import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import AuthorizationClient from "../../single-page/api/AuthorizationClient";
import { GreenButton } from "../config/theme";
import {
  RegistrationInputText,
  RegistrationSelect,
} from "./RegistrationInputs";
import { legacyMaskDate, legacyMaskCPF, legacyMaskPhone } from "./TextMaskCustom";
import FormSubtitle from '../../components/FormSubtitle';
import RadioGroupField from '../../components/RadioGroupField';
import CustomCheckboxGroup from "../../components/CheckboxGroupField";

import { Checkbox, Link, FormControlLabel, MenuItem } from "@material-ui/core";
import axios from 'axios';

const INITIAL_APPLICANT = {
  name: "",
  email: "",
  phone_number: "",
  country: 'Brasil',
  city: "",
  ca_user: "",
  website_channels: "",
  instagram_url: "",
  twitter_url: "",
  facebook_url: "",
  statistic_data_name: "",
  statistic_data_bin: "",
  operation_type: "",
  agree_with_terms: false,
  password: '',
  password_confirmation: '',
  cpf: '',
};

const nameFormat = /\b\w{2,}\s\w{2,}/;
const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function RegistrationForm({ affiliateApiToken }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [operationType, setOperationType] = useState('');
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [state, setState] = useState('');
  const [skipEmail, setSkipEmail] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const fileRef = useRef(null);

  // Dynamic state questions
  const [bettingMarketGroupQuestion, setBettingMarketGroupQuestion] = useState(null);
  const [socialMediaGroupQuestion, setSocialMediaGroupQuestion] = useState({
    instagram: null,
    facebook: null,
    tiktok: null,
    telegram: null,
    twitch: null,
    twitter: null,
    other: null,
    ausente: null,
  });
  const [socialMediaWasChecked, setSocialMediaWasChecked] = useState(false);

  const [profileTypeGroupQuestion, setProfileTypeGroupQuestion] = useState({
    tipster: null,
    trade_esportivo: null,
    streamer_gamer: null,
    tik_toker: null,
    twitcher: null,
    youtuber: null,
    trafic_manager: null,
    onlyfans: null,
    web_publisher: null,
    grau: null,
    influencer: null,
    punter: null,
    other: null,
  });
  const [profileTypeWasChecked, setProfileTypeWasChecked] = useState(false);

  const [paidTrafficGroupQuestion, setPaidTrafficGroupQuestion] = useState(null);

  // Related states
  const [bettingMarketRelatedGroupQuestions, setBettingMarketRelatedGroupQuestions] = useState({
    bettingTime: '',
    affiliateAnotherHouse: '',
    workWithSportsBetting: '',
  });

  const [socialMediaRelatedGroupQuestions, setSocialMediaRelatedGroupQuestions] = useState({
    howManyFollowers: '',
    socialNetworks: '',
  });

  const [profileTypeRelatedGroupQuestions, setProfileTypeRelatedGroupQuestions] = useState({
    segment: '',
    whatYourProfile: '',
  });

  const [paidTrafficRelatedGroupQuestion, setPaidTrafficGroupRelatedGroupQuestion] = useState({
    whatTypePaidTraffic: '',
  });

  const bettingTimeMarketGroupRadioOptions = [
    {
      label: 'Menos de um ano',
      value: 'less_than_one_year',
    }, {
      label: 'Um ano',
      value: 'one_year',
    }, {
      label: 'Dois anos',
      value: 'two_years',
    }, {
      label: 'Mais de dois anos',
      value: 'more_than_two_years',
    }
  ];

  const howManyFollowersGroupQuestionOptions = [
    { label: 'Menos de 1.000', value: 'less_than_thousand'},
    { label: 'Entre 1.000 e 5.000', value: 'thousand_five_thousand'},
    { label: 'Entre 5.001 e 20.000', value: 'five_thousand_one_twenty_thousand'},
    { label: 'Mais de 20.000', value: 'more_than_twenty_thousand'},
  ];

  const socialMediaGroupQuestionOptions = [
    { label: 'Instagram', value: 'instagram'},
    { label: 'Facebook', value: 'facebook'},
    { label: 'TikTok', value: 'tiktok'},
    { label: 'Telegram', value: 'telegram'},
    { label: 'Twitch', value: 'twitch'},
    { label: 'Twitter', value: 'twitter'},
    { label: 'Outro', value: 'other'},
    { label: 'Não tenho', value: 'ausente'},
  ];

  const profileTypeGroupQuestionOptions = [
    { label: 'Tipster', value: 'tipster'},
    { label: 'Trader Esportivo', value: 'trade_esportivo'},
    { label: 'Streamer Gamer', value: 'streamer_gamer'},
    { label: 'Tik Toker', value: 'tik_toker'},
    { label: 'Twitcher', value: 'twitcher'},
    { label: 'Youtuber', value: 'youtuber'},
    { label: 'Trafic manager', value: 'trafic_manager'},
    { label: 'Onlyfans', value: 'onlyfans'},
    { label: 'Web Publisher', value: 'web_publisher'},
    { label: 'Grau', value: 'grau'},
    { label: 'Influencer', value: 'influencer'},
    { label: 'Punter', value: 'punter'},
    { label: 'Outro', value: 'other'},
  ];

  const segmentProfileTypeGroupQuestionOptions = [
    { label: 'Esportes', value: 'bet_sports'},
    { label: 'Cassino', value: 'casino'},
    { label: 'Ambos', value: 'both'},
  ];
    
  // Handle methods
  function handleBettingMarketGroupQuestionChange(event) {
    const value = event.target.value === 'true';
    setBettingMarketGroupQuestion(value);
  }

  function handleSocialMediaGroupQuestionChange(event) {
    setSocialMediaGroupQuestion({
      ...socialMediaGroupQuestion,
      [event.target.name]: event.target.checked
    });
    if (event.target.checked) { setSocialMediaWasChecked(true) }
  }

  function handleProfileTypeGroupQuestionChange(event) {
    setProfileTypeGroupQuestion({
      ...profileTypeGroupQuestion,
      [event.target.name]: event.target.checked
    });
    if (event.target.checked) { setProfileTypeWasChecked(true) }
  };

  function handlePaidTrafficGroupQuestionChange(event) {
    setPaidTrafficGroupQuestion(event.target.value);
  };

  // Handle related methods
  function handleBettingMarketRelatedGroupQuestionsChange(event) {
    setBettingMarketRelatedGroupQuestions({
      ...bettingMarketRelatedGroupQuestions,
      [event.target.name]: event.target.value
    });
    checkFieldTextIsValid(event.target.name, event.target.value);
  }

  function handlesocialMediaRelatedGroupQuestions(event) {
    setSocialMediaRelatedGroupQuestions({
      ...socialMediaRelatedGroupQuestions,
      [event.target.name]: event.target.value
    });
  }

  function handleProfileTypeRelatedGroupQuestions(event) {
    setProfileTypeRelatedGroupQuestions({
      ...profileTypeRelatedGroupQuestions,
      [event.target.name]: event.target.value
    });
    checkFieldTextIsValid(event.target.name, event.target.value);
  }

  function handlePaidTrafficRelatedGroupQuestions(event) {
    setPaidTrafficGroupRelatedGroupQuestion({
      ...paidTrafficRelatedGroupQuestion,
      [event.target.name]: event.target.value
    });
    checkFieldTextIsValid(event.target.name, event.target.value);
  }

  // Show related questions
  function showBettingMarketRelatedGroupQuestions() {
    return bettingMarketGroupQuestion;
  }

  function showSocialMediaRelatedGroupQuestions() {
    return socialMediaGroupIsValid();
  }

  function showProfileTypeRelatedGroupQuestions() {
    return profileTypeGroupQuestion;
  }

  function showPaidTrafficRelatedGroupQuestions() {
    return paidTrafficGroupQuestion;
  }

  const inputCpfRef = useRef();
  const inputNameRef = useRef();
  const inputPhoneRef = useRef();
  const inputEmailRef = useRef();
  const inputPasswordRef = useRef();
  const inputPasswordConfirmationRef = useRef();
  const inputBirthdateRef = useRef();
  const inputCityRef = useRef();
  const inputFileNameRef = useRef();
  const inputWhyAffiliate = useRef();

  const [applicant, setApplicant] = useState(INITIAL_APPLICANT);

  function setResposeErrors(data) {
    if (data.email) {
      setErrors({ ...errors, inputEmailRef: data.email });
    }
    if (data.documentId) {
      setErrors({ ...errors, inputCpfRef: data.documentId });
    }
    if (data.cpf) {
      setErrors({ ...errors, inputCpfRef: data.cpf });
    }
  }

  function createUser() {
    dataLayer.push({'event': 'register_finish'});
    document.activeElement.blur();
    setLoading(true);
    const obj = {applicant: {
      password: inputPasswordRef.current.value,
      name: inputNameRef.current.value,
      phone: inputPhoneRef.current.value,
      cpf: (inputCpfRef.current.value || '').replace(
        /[^\w\s]/gi,
        ''
      ),
      email: inputEmailRef.current.value,
      sendNewsLetterEmail: true,
      birthdate: inputBirthdateRef.current.value,
      bonusAcceptance: 0, // Valor default para ser aceito pela API
    }};

    axios
      .post(`/api/v1/applicants/register`, obj, { headers: { token: affiliateApiToken } })
      .then((res) => {
        const { user } = res.data;
        const partnerId = user.id;

        signUp({ partner_id: partnerId });
      })
      .catch((error) => {
        const { response } = error;
        if (!response) throw new Error(error);

        const { data } = response;
        if ((data && response.status === 422)) {
          setResposeErrors(data);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  function signUp(customParams) {
    // e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    toast.dismiss();

    const applicantParams = {
      ...applicant,
      ...customParams,
      state,
      cpf: inputCpfRef.current.value,
      email: inputEmailRef.current.value,
      birthdate: inputBirthdateRef.current.value,
      name: inputNameRef.current.value,
      phone_number: inputPhoneRef.current.value,
      city: inputCityRef.current.value,
      password: inputPasswordRef.current.value,
      password_confirmation: inputPasswordConfirmationRef.current.value,
      agree_with_terms: agreeWithTerms,
      // Dynamic group questions
      betting_market_group_question: bettingMarketGroupQuestion,
      social_media_group_question: socialMediaGroupQuestion,
      profile_type_group_question: profileTypeGroupQuestion,
      paid_traffic_group_question: paidTrafficGroupQuestion,
      betting_market_related_group_questions: bettingMarketRelatedGroupQuestions,
      social_media_related_group_questions: socialMediaRelatedGroupQuestions,
      profile_type_related_group_questions: profileTypeRelatedGroupQuestions,
      paid_traffic_related_group_question: paidTrafficRelatedGroupQuestion,
      reason_to_affiliate: inputWhyAffiliate.current.value,
    };

    // SIGN UP NÃO VEM HABILITADO POR PADRÃO NO TEMPLATE!
    AuthorizationClient.registerApplicant(applicantParams)
      .then(() => {
        toast.success('Seu cadastro foi efetuado com sucesso.');
        clearForm();
        setErrors({});
        setApplicant(INITIAL_APPLICANT);
        // history.push("/public");
      })
      .catch((response) => {
        if (response.status === 409) {
          toast.error('Sua solicitação está em análise.');
        } if (response.status === 422) {
          setErrors(response.data);
          toast.error(response.data.error);
        } else {
          setErrors({});
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  function clearForm() {
    inputNameRef.current.value = '';
    inputEmailRef.current.value = '';
    inputCpfRef.current.value = '';
    inputPhoneRef.current.value = '';
    inputBirthdateRef.current.value = dataFormatada;
    inputCityRef.current.value = '';
    inputPasswordRef.current.value = '';
    inputPasswordConfirmationRef.current.value = '';
    inputFileNameRef.current.value = '';
    inputWhyAffiliate.current.value = '';
    setState('');
    setBettingMarketGroupQuestion(null);
    setSocialMediaGroupQuestion({
      instagram: false,
      facebook: false,
      tiktok: false,
      telegram: false,
      twitch: false,
      twitter: false,
      other: false,
      ausente: false,
    });
    setProfileTypeGroupQuestion(
      {
        tipster: false,
        trade_esportivo: false,
        streamer_gamer: false,
        tik_toker: false,
        twitcher: false,
        youtuber: false,
        trafic_manager: false,
        onlyfans: false,
        web_publisher: false,
        grau: false,
        influencer: false,
        punter: false,
        other: false,
      }
    );
    setPaidTrafficGroupQuestion(null);
    setBettingMarketRelatedGroupQuestions({
      bettingTime: '',
      affiliateAnotherHouse: '',
      workWithSportsBetting: '',
    });
    setSocialMediaRelatedGroupQuestions({
      howManyFollowers: '',
      socialNetworks: '',
    });
    setProfileTypeRelatedGroupQuestions({
      segment: '',
      whatYourProfile: '',
    });
    setPaidTrafficGroupRelatedGroupQuestion({
      whatTypePaidTraffic: '',
    });
  }

  function handleChange(e) {
    const { name, value, checked } = e.target;
    setApplicant({
      ...applicant,
      [name]: name === 'agree_with_terms' ? checked : value,
    });
  }

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setState(value);
    // setApplicant({ ...applicant, [name]: value });
  };

  function openFileWindow() {
    fileRef.current.click();
  }

  function handleFileInputChange() {
    const [file] = fileRef.current.files;

    if (!file) return null;

    if (Math.round(file.size / 1000) > 20000) {
      setErrors({
        ...errors,
        statistic_data_name: 'Tamanho máximo de arquivo: 20mb',
      });
    } else {
      setApplicant({
        ...applicant,
        statistic_data_name: file.name,
        statistic_data_bin: file,
      });
    }

    return undefined;
  }

  // const countries = ['Brasil'];
  const states = [
    'Acre',
    'Alagoas',
    'Amapá',
    'Amazonas',
    'Bahia',
    'Ceará',
    'Distrito Federal',
    'Espírito Santo',
    'Goiás',
    'Maranhão',
    'Mato Grosso',
    'Mato Grosso do Sul',
    'Minas Gerais',
    'Pará',
    'Paraíba',
    'Paraná',
    'Pernambuco',
    'Piauí',
    'Rio de Janeiro',
    'Rio Grande do Norte',
    'Rio Grande do Sul',
    'Rondônia',
    'Roraima',
    'Santa Catarina',
    'São Paulo',
    'Sergipe',
    'Tocantins',
  ];

  useEffect(() => {
    // legacyMaskDate(inputBirthdateRef);
    legacyMaskCPF(inputCpfRef);
    legacyMaskPhone(inputPhoneRef);
  }, []);

  useEffect(() => {
    checkSocialMediaGroupQuestion();
  }, [socialMediaGroupQuestion]);

  useEffect(() => {
    checkProfileTypeGroupQuestion();
  }, [profileTypeGroupQuestion]);

  useEffect(() => {
    validateForm();
  }, [
    state,
    agreeWithTerms,
    bettingMarketGroupQuestion,
    socialMediaGroupQuestion,
    profileTypeGroupQuestion,
    paidTrafficGroupQuestion,
    bettingMarketRelatedGroupQuestions.bettingTime,
    bettingMarketRelatedGroupQuestions.affiliateAnotherHouse,
    bettingMarketRelatedGroupQuestions.workWithSportsBetting,
    // socialMediaRelatedGroupQuestions.howManyFollowers,
    // socialMediaRelatedGroupQuestions.socialNetworks,
    profileTypeRelatedGroupQuestions.segment,
    profileTypeRelatedGroupQuestions.whatYourProfile,
    paidTrafficRelatedGroupQuestion.whatTypePaidTraffic
  ]);

  function checkCPF(strCPF) {
    let sum;
    let rest;
    sum = 0;
    if (strCPF === '00000000000') return false;

    for (let i = 1; i <= 9; i++)
      sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(strCPF.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function getCPFInformation() {
    const cpfFormatted = inputCpfRef.current.value.replace(/[^\w\s]/gi, '');
    if (!checkCPF(cpfFormatted)) return;

    setLoading(true);
    axios
      .get(`/api/v1/applicants/verify_cpf?cpf=${inputCpfRef.current.value.replace(/\D/g, '')}`, { headers: { token: affiliateApiToken } })
      .then(({ data }) => {
        if (data) {
          inputNameRef.current.value = data.nome;
          inputBirthdateRef.current.value = data.nascimento;

          inputNameRef.current.focus();
          inputNameRef.current.blur();
        }
      })
      .catch(() => {
        // console.log('Erro ao consultar CPF', response);
      })
      .then(() => {
        setLoading(false);
        // validateForm();
      });
  }

  function adicionaZero(numero) {
    if (numero <= 9) return '0' + numero;
    else return numero;
  }

  let data = new Date();
  let dataFormatada = data.getFullYear() - 18 + '-' + adicionaZero(data.getMonth() + 1) + '-' + adicionaZero(data.getDate());
  const minDate = '1920-01-01';

  function checkPasswordFormat() {
    if (
      (inputPasswordRef.current && inputPasswordRef.current.value.length >= 7) ||
      inputPasswordRef.current.value.length === 0
    ) {
      setErrors({ ...errors, inputPasswordRef: '' });
    } else {
      setErrors({
        ...errors,
        inputPasswordRef: 'Senha deve conter 8 dígitos',
      });
    }
  }

  const ensureCondition = (condition) => {
    if (!condition) throw new Error('');
  };

  const validateForm = useCallback(() => {
    try {
      ensureCondition(inputNameRef.current.value);

      if (!skipEmail) ensureCondition(inputEmailRef.current.value);

      ensureCondition(inputCpfRef.current.value);
      ensureCondition(inputPhoneRef.current.value);
      ensureCondition(inputPasswordRef.current.value);
      ensureCondition(inputPasswordConfirmationRef.current.value);
      ensureCondition(inputBirthdateRef.current.value);
      ensureCondition(agreeWithTerms);
      ensureCondition(inputWhyAffiliate.current.value);

      ensureCondition(bettingMarketGroupIsValid());
      ensureCondition(socialMediaGroupIsValid(
        socialMediaGroupQuestion.ausente
      ));
      ensureCondition(profileTypeGroupIsValid());
      ensureCondition(checkTipsterAndPunter() || checkOTherProfile());
      ensureCondition(paidTrafficGroupIsValid());

      if (!skipEmail) ensureCondition(!errors.inputEmailRef);

      setValidForm(true);
    } catch (err) {
      setValidForm(false);
    }
  }, [
      agreeWithTerms,
      skipEmail,
      errors,
      bettingMarketGroupQuestion,
      socialMediaGroupQuestion,
      profileTypeGroupQuestion,
      paidTrafficGroupQuestion,
      bettingMarketRelatedGroupQuestions.bettingTime,
      bettingMarketRelatedGroupQuestions.affiliateAnotherHouse,
      bettingMarketRelatedGroupQuestions.workWithSportsBetting,
      // socialMediaRelatedGroupQuestions.howManyFollowers,
      // socialMediaRelatedGroupQuestions.socialNetworks,
      profileTypeRelatedGroupQuestions.segment,
      profileTypeRelatedGroupQuestions.whatYourProfile,
      paidTrafficRelatedGroupQuestion.whatTypePaidTraffic
  ]);
 
  // Validate betting market group questions
  const bettingMarketGroupIsValid = () => {
    return (
      bettingMarketGroupQuestion === false ||
      (bettingMarketGroupQuestion === true &&
        bettingMarketRelatedGroupQuestions.bettingTime !== '' &&
        bettingMarketRelatedGroupQuestions.affiliateAnotherHouse !== '' &&
        hasMoreThanFiveValidCharacters(bettingMarketRelatedGroupQuestions.workWithSportsBetting)
      )
    );
  };

  // Validate social media group questions
  const socialMediaGroupIsValid = (customValidation = false) => {
    return (
      socialMediaGroupQuestion.instagram  ||
      socialMediaGroupQuestion.facebook  ||
      socialMediaGroupQuestion.tiktok ||
      socialMediaGroupQuestion.telegram ||
      socialMediaGroupQuestion.twitch ||
      socialMediaGroupQuestion.twitter ||
      socialMediaGroupQuestion.other  ||
      customValidation
    );
  }; 

  // Validate profile type group questions
  const profileTypeGroupIsValid = () => {
    return (
      profileTypeGroupQuestion.trade_esportivo ||
      profileTypeGroupQuestion.streamer_gamer ||
      profileTypeGroupQuestion.tik_toker ||
      profileTypeGroupQuestion.twitcher ||
      profileTypeGroupQuestion.youtuber ||
      profileTypeGroupQuestion.trafic_manager ||
      profileTypeGroupQuestion.onlyfans ||
      profileTypeGroupQuestion.web_publisher ||
      profileTypeGroupQuestion.grau ||
      profileTypeGroupQuestion.influencer ||
      profileTypeGroupQuestion.punter ||
      profileTypeGroupQuestion.tipster ||
      profileTypeGroupQuestion.other
    );
  };

  // Auxiliar methods to validate profile type group questions
  function checkTipsterAndPunter() {
    return (profileTypeGroupQuestion.tipster || profileTypeGroupQuestion.punter) && profileTypeRelatedGroupQuestions.segment !== '';
  }

  function checkOTherProfile() {
    return profileTypeGroupQuestion.other && hasMoreThanFiveValidCharacters(profileTypeRelatedGroupQuestions.whatYourProfile);
  }

  // Validate traffic group questions
  const paidTrafficGroupIsValid = () => {
    return (
      paidTrafficGroupQuestion === true && hasMoreThanFiveValidCharacters(paidTrafficRelatedGroupQuestion.whatTypePaidTraffic)
    ) || paidTrafficGroupQuestion === false;
  };

  // Verify if the string has more than five valid characters
  const hasMoreThanFiveValidCharacters = (str) => {
    return typeof str === 'string' && str.trim().length > 5;
  };


  function checkNameFormat() {
    if (
      (inputNameRef.current && inputNameRef.current.value.match(nameFormat)) ||
      inputNameRef.current.value.length === 0
    ) {
      setErrors({ ...errors, inputNameRef: '' });
    } else {
      setErrors({ ...errors, inputNameRef: 'Nome muito curto' });
    }
  }

  function checkEmailFormat() {
    if (
      (inputEmailRef.current && inputEmailRef.current.value.match(mailformat)) ||
      inputEmailRef.current.value.length === 0
    ) {
      setErrors({ ...errors, inputEmailRef: '' });
    } else {
      setErrors({ ...errors, inputEmailRef: 'Email inválido' });
    }
  }


  // Campo se verifica se campo tem texto válido
  function checkFieldTextIsValid(field, text) {
    if (field && hasMoreThanFiveValidCharacters(text)) {
      setErrors({ ...errors, [field]: '' });
    } else {
      setErrors({ ...errors, [field]: 'É muito curto' });
    }
  }

  // Verifica se o campo de redes sociais foi preenchido
  function checkSocialMediaGroupQuestion() {
    if (!socialMediaGroupIsValid(socialMediaGroupQuestion.ausente) && socialMediaWasChecked) {
      setErrors({ ...errors, socialMediaGroupQuestion: 'Selecione ao menos uma opção' });
    } else {
      setErrors({ ...errors, socialMediaGroupQuestion: '' });
    }
  }

  // Verifica se o campo de perfil foi preenchido
  function checkProfileTypeGroupQuestion() {
    if (!profileTypeGroupIsValid() && profileTypeWasChecked) {
      setErrors({ ...errors, profileTypeGroupQuestion: 'Selecione ao menos uma opção' });
    } else {
      setErrors({ ...errors, profileTypeGroupQuestion: '' });
    }
  }
    
  function checkDocumentIsValid() {
    const desired = inputCpfRef.current.value.replace(/[^\w\s]/gi, '');

    if (checkCPF(desired)) {
      setErrors({ ...errors, inputCpfRef: '' });
    } else {
      setErrors({ ...errors, inputCpfRef: 'Formato inválido' });
    }
  }

  function checkPasswordFormat() {
    if (
      (inputPasswordRef.current && inputPasswordRef.current.value.length >= 7) ||
      inputPasswordRef.current.value.length === 0
    ) {
      setErrors({ ...errors, inputPasswordRef: '' });
    } else {
      setErrors({
        ...errors,
        inputPasswordRef: 'Senha deve conter 8 dígitos',
      });
    }
  }

  function checkPasswordConfirmation() {
    if (
      (!!inputPasswordRef.current || !!inputPasswordConfirmationRef.current) &&
      (inputPasswordRef.current.value !==
        inputPasswordConfirmationRef.current.value ||
        (inputPasswordRef.current.value.length < 7 &&
          inputPasswordRef.current.value.length > 0))
    ) {
      setErrors({
        ...errors,
        inputPasswordConfirmationRef:
          'Confirmação difere de senha',
      });
    } else {
      setErrors({ ...errors, inputPasswordConfirmationRef: '' });
    }
  }

  function checkPhoneFormat() {
    if (
      (inputPhoneRef && inputPhoneRef.current.value.length >= 15) ||
      inputPhoneRef.current.value.length === 0
    ) {
      setErrors({ ...errors, inputPhoneRef: '' });
    } else {
      setErrors({ ...errors, inputPhoneRef: 'Telefone inválido' });
    }
  }

  return (
    <div
      name="registration-form"
      className="registration-form"
      style={{ backgroundColor: '#E8E8E8' }}
    >
      <Container
        maxWidth="lg"
        style={{ paddingBottom: '3.125rem', paddingTop: '1.25rem' }}
      >
        <h1
          style={{
            marginTop: 0,
            fontFamily: 'Poppins',
            fontSize: '3.5rem',
            marginBottom: '1rem',
          }}
        >
          Cadastro
        </h1>
  
        <Grid container>
          <Grid item xs={12} sm={6}>
            <p style={{ fontSize: '1rem', margin: 0, marginBottom: '2rem' }}>
              Preencha os dados abaixo para se inscrever no programa de afiliados do Casa de Apostas e venha fazer parte do time do melhor site de apostas do mercado brasileiro!
            </p>
          </Grid>
        </Grid>
  
        <Grid
          container
          spacing={2}
          style={{
            backgroundColor: 'white',
            borderRadius: 3,
            boxShadow: '16px 16px 48px #00000019',
            padding: '4rem',
          }}
        >
          <Grid item xs={12}>
            <FormSubtitle title="Dados" />
          </Grid>
          {/* Left container */}
          <Grid item xs={12} sm={6}>
            <RegistrationInputText
              inputRef={inputCpfRef}
              label="CPF"
              placeholder="CPF"
              name="cpf"
              error={errors.inputCpfRef}
              onBlur={getCPFInformation}
              onChange={() => {
                checkDocumentIsValid();
                validateForm();
              }}
            />

            <RegistrationInputText
              label="NOME"
              placeholder="Nome"
              name="name"
              inputRef={inputNameRef}
              error={errors.inputNameRef}
              onChange={() => {
                checkNameFormat();
                validateForm();
              }}
              onBlur={validateForm}
            />
  
            <RegistrationInputText
              label="EMAIL"
              placeholder="Email"
              name="email"
              inputRef={inputEmailRef}
              error={errors.inputEmailRef}
              onChange={() => {
                checkEmailFormat();
                validateForm();
              }}
            />

            <Grid container spacing={2} style={{ marginBottom: isMobile ? '-20px' : 'unset' }}>
              <Grid item xs={12} sm={6} style={{ marginBottom: isMobile ? '-5px' : 'unset' }}>
                <RegistrationInputText
                  label="TELEFONE"
                  placeholder="Telefone"
                  name="phone_number"
                  error={errors.inputPhoneRef}
                  inputRef={inputPhoneRef}
                  onChange={() => {
                    checkPhoneFormat();
                    validateForm();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RegistrationInputText
                  label="ANIVERSÁRIO"
                  placeholder="Aniversário"
                  name="birthdate"
                  error={errors.birthdate}
                  inputRef={inputBirthdateRef}
                  type="date"
                  defaultValue={dataFormatada}
                  onChange={validateForm}
                  inputProps={{
                    max: dataFormatada,
                    min: minDate,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
  
          {/* Right container */}
          <Grid item xs={12} sm={6}> 
            <Grid container spacing={2} style={{ marginBottom: isMobile ? 'unset' : '-23px' }}>
              <Grid item xs={12} sm={6} style={{ marginBottom: isMobile ? '-10px' : 'unset' }}>
                <RegistrationSelect
                  values={states}
                  label="ESTADO"
                  placeholder="Estado"
                  name="state"
                  value={state}
                  error={errors.state}
                  handleSelectChange={handleSelectChange}
                >
                  {states.map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </RegistrationSelect>
              </Grid>
              <Grid item xs={12} sm={6}>
                <RegistrationInputText
                  label="CIDADE"
                  placeholder="Cidade"
                  name="city"
                  inputRef={inputCityRef}
                  error={errors.city}
                  onChange={validateForm}
                />
              </Grid>
            </Grid>
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{ marginBottom: isMobile ? '-10px' : 'unset' }}>
                <RegistrationInputText
                  label="SENHA"
                  placeholder="Senha"
                  name="password"
                  type="password"
                  error={errors.inputPasswordRef}
                  inputRef={inputPasswordRef}
                  onChange={() => {
                    checkPasswordFormat();
                    validateForm();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: isMobile ? '10px' : 'unset' }}>
                <RegistrationInputText
                  label="CONFIRMAÇÃO DE SENHA"
                  placeholder="Confirmação de senha"
                  name="password_confirmation"
                  type="password"
                  error={errors.inputPasswordConfirmationRef}
                  inputRef={inputPasswordConfirmationRef}
                  onChange={() => {
                    checkPasswordConfirmation();
                    validateForm();
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ position: "relative" }}>
              <p
                style={{
                  lineHeight: 1,
                  margin: 0,
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  color: "#333333",
                  fontFamily: "Source Sans Pro",
                }}
              >
                ENVIE EM ANEXO AS ESTATÍSTICAS OU DADOS ANALÍTICOS DAS SUAS PÁGINAS
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '100%', marginRight: 10 }}>
                  <RegistrationInputText
                    label=""
                    onClick={openFileWindow}
                    disabled={true}
                    placeholder="Clique no botão para selecionar"
                    name="statistic_data_name"
                    value={applicant.statistic_data_name}
                    inputRef={inputFileNameRef}
                    error={errors.statistic_data_name ? ' ' : null}
                  />
                </div>
                <GreenButton
                  onClick={openFileWindow}
                  style={{ marginLeft: 'auto', transform: 'translateY(-5px)' }}
                >
                  SELECIONAR
                </GreenButton>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileRef}
                  onChange={handleFileInputChange}
                />
              </div>
              {errors.statistic_data_name && (
                <span
                  style={{
                    color: 'crimson',
                    fontFamily: 'Source Sans Pro',
                    position: 'absolute',
                    bottom: 0,
                  }}
                >
                  {errors.statistic_data_name}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormSubtitle title="Apostas" />
            {/* First dynamic question */}
            <Grid item xs={12}>
              <RadioGroupField
                required
                name="bettingMarketGroupQuestion"
                title="VOCÊ JÁ TRABALHA COM O MERCADO DE APOSTAS ESPORTIVAS?"
                value={bettingMarketGroupQuestion}
                onChange={handleBettingMarketGroupQuestionChange}
                onBlur={validateForm}
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
                orientation={isMobile ? 'vertical' : 'horizontal'}
              />
            </Grid>
  
            {/* Related first questions */}
            {showBettingMarketRelatedGroupQuestions() && (
              <>
                <Grid item xs={12}>
                  <RadioGroupField
                    required
                    name="bettingTime"
                    title="QUANTO TEMPO VOCÊ CONHECE O MERCADO DE APOSTAS?"
                    value={bettingMarketRelatedGroupQuestions.bettingTime}
                    onChange={handleBettingMarketRelatedGroupQuestionsChange}
                    options={bettingTimeMarketGroupRadioOptions}
                    orientation={isMobile ? 'vertical' : 'horizontal'} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                  <RadioGroupField
                    required
                    name="affiliateAnotherHouse"
                    title="JÁ É AFILIADO DE OUTRAS CASAS?"
                    value={bettingMarketRelatedGroupQuestions.affiliateAnotherHouse}
                    onChange={handleBettingMarketRelatedGroupQuestionsChange}
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                  />
                </Grid>
  
                <Grid item sm={6} xs={12} style={{ marginBottom: '1rem' }}>
                  <RegistrationInputText
                    required
                    multiline
                    rows="3"
                    name="workWithSportsBetting"
                    aria-label="work-with-sports-betting"
                    label="AFILIADO EM QUAL CASA DE APOSTA?"
                    maxHeight="80px"
                    onChange={handleBettingMarketRelatedGroupQuestionsChange}
                    value={paidTrafficRelatedGroupQuestion.workWithSportsBetting}
                    error={errors.workWithSportsBetting}
                    forRegistration
                  />
                </Grid>
              </>
            )}
          </Grid>
  
          <Grid item xs={12}> 
            <FormSubtitle title="Redes Sociais" />
            <Grid item xs={12}>
              <CustomCheckboxGroup
                label="QUAL OU QUAIS DESSAS REDES, ATUALMENTE VOCÊ MAIS UTILIZA?"
                options={socialMediaGroupQuestionOptions}
                checkedValues={socialMediaGroupQuestion}
                onChange={handleSocialMediaGroupQuestionChange}
                error={errors.socialMediaGroupQuestion}
                required
              />
            </Grid>
  
            {/* Related second questions */}
            {showSocialMediaRelatedGroupQuestions() && (
              <>
                <Grid item xs={12}>
                  <RadioGroupField
                    name="howManyFollowers"
                    title="QUANTOS SEGUIDORES VOCÊ POSSUI?"
                    value={socialMediaRelatedGroupQuestions.howManyFollowers}
                    onChange={handlesocialMediaRelatedGroupQuestions}
                    options={howManyFollowersGroupQuestionOptions}
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                  />
                </Grid>
                <Grid item sm={6} xs={12} style={{ marginBottom: '1rem' }}>
                  <RegistrationInputText
                    label="QUAIS REDES UTILIZA PARA TRABALHAR?"
                    multiline
                    maxHeight="80px"
                    rows="4"
                    name="socialNetworks"
                    value={socialMediaRelatedGroupQuestions.socialNetworks}
                    onChange={handlesocialMediaRelatedGroupQuestions}
                    forRegistration
                  />
                </Grid>
              </>
            )}
          </Grid>
  
          <Grid item xs={12}>
            <FormSubtitle title="Perfil" />
            <Grid item sm={9} xs={12}>

              <CustomCheckboxGroup
                label="VOCÊ SE CONSIDERA:"
                options={profileTypeGroupQuestionOptions}
                checkedValues={profileTypeGroupQuestion}
                onChange={handleProfileTypeGroupQuestionChange}
                error={errors.profileTypeGroupQuestion}
                required
              />
            </Grid>

            {/* Related third questions */}
            {showProfileTypeRelatedGroupQuestions() && (
              <>
                {(profileTypeGroupQuestion.tipster ||
                  profileTypeGroupQuestion.punter) && (
                  <Grid item xs={12}>
                    <RadioGroupField
                      name="segment"
                      title="QUAL E O SEU SEGUIMENTO?"
                      value={profileTypeGroupQuestion.segment}
                      onChange={handleProfileTypeRelatedGroupQuestions}
                      options={segmentProfileTypeGroupQuestionOptions}
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                    />
                  </Grid>
                )}
                {profileTypeGroupQuestion.other && (
                  <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                    <RegistrationInputText
                      required
                      aria-label="what-your-profile"
                      name="whatYourProfile"
                      label="QUAL SEU PERFIL?"
                      maxHeight="80px"
                      rows="4"
                      multiline
                      value={profileTypeRelatedGroupQuestions.whatYourProfile}
                      onChange={handleProfileTypeRelatedGroupQuestions}
                      error={errors.whatYourProfile}
                      forRegistration
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
  
          <Grid item sm={6} xs={12} style={{ marginBottom: '1rem' }}>
            <RegistrationSelect
              values={[true, false]}
              label="VOCÊ TRABALHA COM TRÁFEGO PAGO?"
              placeholder="SELECIONE"
              name="paidTrafficGroupQuestion"
              value={paidTrafficGroupQuestion}
              handleSelectChange={handlePaidTrafficGroupQuestionChange}
              forRegistration
              required
            >
              {[true, false].map((v) => (
                <MenuItem key={v} value={v}>
                  {v === true ? 'Sim' : 'Não'}
                </MenuItem>
              ))}
            </RegistrationSelect>
          </Grid>
  
          {/* Related fourth questions */}
          {showPaidTrafficRelatedGroupQuestions() && (
            <Grid item xs={12} style={{ marginBottom: '1rem' }}>
              <RegistrationInputText
                required
                rows="3"
                multiline
                label="COM QUAL TIPO DE TRÁFEGO VOCÊ TRABALHA?"
                maxHeight="80px"
                onChange={handlePaidTrafficRelatedGroupQuestions}
                aria-label="what_type_paid_traffic"
                name="whatTypePaidTraffic"
                value={paidTrafficRelatedGroupQuestion.whatTypePaidTraffic}
                error={errors.whatTypePaidTraffic}
                forRegistration
              />
            </Grid>
          )}
  
          <Grid item xs={12}>
            <RegistrationInputText
              label="POR QUE VOCÊ QUER SER UM AFILIADO?"
              name="whyAfiliate"
              maxHeight="80px"
              rows="3"
              multiline
              inputRef={inputWhyAffiliate}
              error={errors.inputWhyAffiliate}
              onChange={() => {
                checkFieldTextIsValid('inputWhyAffiliate', inputWhyAffiliate.current.value);
                validateForm();
              }}
              forRegistration
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="agree_with_terms"
                  color="success"
                  error={errors.agree_with_terms}
                  onChange={() => {
                    setAgreeWithTerms((prev) => !prev);
                  }}
                />
              }
              label={
                <p style={errors.agree_with_terms ? { color: 'red' } : {}}>
                  Li e aceito os&nbsp;
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('/termos', '_blank').focus();
                    }}
                  >
                    Termos e Condições
                  </Link>
                  &nbsp;do site Afiliados Casa de Apostas
                </p>
              }
            />
            {errors.agree_with_terms && <span style={{ color: 'red' }}>*</span>}
          </Grid>
  
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <GreenButton
              style={{ maxWidth: 544 }}
              disabled={loading || !validForm}
              onClick={createUser}
              fullWidth
            >
              {loading ? 'Aguarde...' : 'Enviar'}
            </GreenButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );  
}