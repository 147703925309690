import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import SignIn from "./SignIn";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Terms from "./pages/Terms";
import ScrollToTop from "./config/ScrollToTop";
import Politics from "./pages/Politics";
import ResetPassword from "./ResetPassword";
import RequestPasswordReset from "./RequestPasswordReset";
import bgImage from "images/header-background.png";
import logo from "images/logo.svg";
import PublicForm from './pages/PublicForm';

import PublicThemeProvider from "./config/theme";
import "react-toastify/dist/ReactToastify.css";
import "./styles/main.scss";

const signInTheme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiTextField: {
      // The default props to change
      variant: "outlined",
      margin: "normal",
      fullWidth: true,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    position: "absolute",
    top: 30,
    left: 30,
    "@media(max-width: 499px)": {
      width: 180,
    },
  },
}));

export default function Public(props) {
  const { flash, pageInfo, operation_types: operationTypes, api_url: apiUrl, api_token: apiToken, affiliate_api_token: affiliateApiToken } = props;
  const classes = useStyles();

  const noLayoutPaths = ['/cadastroafiliado', '/cadastroafiliado/sucesso'];
  function noLayout() {
    return noLayoutPaths.includes(window.location.pathname);
  }

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
  }, []);

  return (
    <div>
      <ToastContainer autoClose={8000} />
      <div>
        <Router>
          <ScrollToTop />
          <Route exact path="/">
            <PublicThemeProvider>
              <Home
                pageInfo={pageInfo}
                operationTypes={operationTypes}
                apiUrl={apiUrl}
                affiliateApiToken={affiliateApiToken}
              />
            </PublicThemeProvider>
          </Route>
          <Route exact path="/termos">
            <ThemeProvider theme={signInTheme}>
              <Terms publicView termsContent={pageInfo.page_terms} />
            </ThemeProvider>
          </Route>
          <Route exact path="/politicas">
            <PublicThemeProvider>
              <Politics publicView politicsContent={pageInfo.page_policts} />
            </PublicThemeProvider>
          </Route>
          <Route exact path="/login">
            <ThemeProvider theme={signInTheme}>
              <div className={classes.paper}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <img src={logo} alt="logo" className={classes.logo} />
                </Link>
                <SignIn />
              </div>
            </ThemeProvider>
          </Route>
          <Route exact path="/formulario">
            <PublicThemeProvider >
              <PublicForm apiUrl={apiUrl} affiliateApiToken={affiliateApiToken} />
            </PublicThemeProvider>
          </Route>
          <Route exact path="/esqueci-minha-senha">
            <ThemeProvider theme={signInTheme}>
              <div className={classes.paper}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <img src={logo} alt="logo" className={classes.logo} />
                </Link>
                <RequestPasswordReset />
              </div>
            </ThemeProvider>
          </Route>
          <Route exact path="/recuperar-senha">
            <ThemeProvider theme={signInTheme}>
              <div className={classes.paper}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <img src={logo} alt="logo" className={classes.logo} />
                </Link>
                <ResetPassword />
              </div>
            </ThemeProvider>
          </Route>
          {!noLayout() && <Footer />}
        </Router>
      </div>
    </div>
  );
}
