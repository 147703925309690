import React from 'react';
import {
  Typography,
  Paper,
  Grid,
  Container,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ImgAbout1 from 'images/about1.svg';
import ImgAbout2 from 'images/about2.svg';
import ImgAbout3 from 'images/about3.svg';
import ImgAbout4 from 'images/about4.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '40px',
    margin: 0,
    height: '400px',
    width: '270px',
    letterSpacing: 0,
    opacity: 1,
    '@media(max-width: 768px)': {
      margin: '0 auto',
    },
  },
  ball: {
    width: '48px',
    height: '48px',
    border: '1px solid #7ED321',
    borderRadius: '24px',
    opacity: 1,
    marginTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plus: {
    fontSize: '2.5em',
    color: '#7ED321',
    opacity: 1,
  },
}));

export default function About() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <div className={classes.root}>
        <Container maxWidth="lg" style={{paddingBottom: '3.125rem' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h5 style={{fontFamily: 'poppins', margin: 0, paddingTop: '4rem', fontSize: '1rem' }}>VEJA COMO É SIMPLES</h5>
            </Grid>
            <Grid item xs={12} md={6}>
              <h1 style={{fontFamily: 'poppins', margin: 0, fontSize: '3.5rem', marginBottom: '2.31rem', lineHeight: '1' }}>Comece a ganhar uma renda extra</h1>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" style={{ color: '#333333', fontSize: '1.1em', bold: true, paddingTop: '0.625rem', fontWeight: 'bold' }}>
                Com o Programa de Afiliados do Casa de Apostas, você tem a possibilidade de gerar renda de qualquer lugar! Você não precisa investir nenhum dinheiro e já pode começar a lucrar desde o primeiro dia!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Paper className={classes.paper}>
                <Typography variant="caption" bold="true" style={{color: '#333333'}} align="left">
                  Passo 1
                </Typography>
                <Typography variant="h5" bold="true" align="left" style={{fontSize: '1.5rem', lineHeight: '1', fontFamily: 'poppins', marginTop: 24, color: '#333333', fontWeight: 'bold' }}>
                  Inscreva-se
                </Typography>
                <Typography variant="body2" bold="true" style={{ height: 76, marginTop: 6, color: '#333333', fontWeight: 'bold' }} align="left">
                  Receba seu código promocional
                </Typography>
                <div className={classes.root} style={{ justifyContent: 'center', alignItems: 'center', textAlignLast: 'center', textAlign: 'center' }}>
                  <img alt="Inscreva-se" src={ImgAbout1} style={{ height: '5.91rem' }} />
                </div>
                <div className={classes.ball}>
                  <span className={classes.plus}>+</span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Paper className={classes.paper}>
                <Typography variant="caption" bold="true" style={{ color: '#333333' }} align="left">
                  Passo 2
                </Typography>
                <Typography variant="h5" bold="true" style={{ fontSize: '1.5rem', lineHeight: '1', fontFamily: 'poppins', marginTop: 24, color: '#333333', fontWeight: 'bold' }} align="left">
                  Divulgue
                </Typography>
                <Typography variant="body2" bold="true" style={{ height: 76, marginTop: 6, color: '#333333', fontWeight: 'bold' }} align="left">
                  Indique o Casa de Apostas com seu código promocional para sua rede de contatos
                </Typography>
                <div className={classes.root} style={{ justifyContent: 'center', alignItems: 'center', textAlignLast: 'center', textAlign: 'center' }}>
                  <img alt="Divulgue" src={ImgAbout2} style={{ height: '5.91rem' }}/>
                </div>
                <div className={classes.ball}>
                  <span className={classes.plus}>+</span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Paper className={classes.paper}>
                <Typography variant="caption" bold="true" style={{ color: '#333333' }} align="left">
                  Passo 3
                </Typography>
                <Typography variant="h5" bold="true" style={{ fontSize: '1.4rem', lineHeight: '1', fontFamily: 'poppins', marginTop: 24, color: '#333333', fontWeight: 'bold' }} align="left">
                  Monte sua base
                </Typography>
                <Typography variant="body2" bold="true" style={{ height: 76, marginTop: 6, color: '#333333', fontWeight: 'bold' }} align="left">
                  Crie uma base de jogadores registrados com o seu código
                </Typography>
                <div className={classes.root} style={{ justifyContent: 'center', alignItems: 'center', textAlignLast: 'center', textAlign: 'center' }}>
                  <img alt="Equipe" src={ImgAbout3} style={{ height: '5.91rem' }} />
                </div>
                <div className={classes.ball}>
                  <span className={classes.plus}>+</span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Paper className={classes.paper}>
                <Typography variant="caption" bold="true" style={{color: '#333333'}} align="left">
                  Passo 4
                </Typography>
                <Typography variant="h5" bold="true" style={{ width: '12.87rem', fontSize: '1.5rem', lineHeight: '1', fontFamily: 'poppins', marginTop: 24, color: '#333333', fontWeight: 'bold' }} align="left">
                  Ganhe Dinheiro
                </Typography>
                <Typography variant="body2" bold="true" style={{ width: '12.56rem', height: 76, marginTop: 6, color: '#333333', fontWeight: 'bold' }} align="left">
                  Os seus indicados irão jogar e você será comissionado por isso!
                </Typography>
                <div className={classes.root} style={{ justifyContent: 'center', alignItems: 'center', textAlignLast: 'center', textAlign: 'center' }}>
                  <img alt="Ganhe Dinheiro" src={ImgAbout4} style={{ height: '5.91rem' }} />
                </div>
                <div className={classes.ball}>
                  <span className={classes.plus}>+</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
