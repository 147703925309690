import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imgBall: {
    marginRight: '1.75rem',
    boxShadow: '16px 16px 48px #00000019',
    width: 140,
    height: 140,
    borderRadius: '50%',
    '@media(max-width: 499px)': {
      width: 100,
      height: 100,
    },
    '@media(max-width: 320px)': {
      width: 75,
      height: 75,
    },
  },
}));

export default function Testimonials({ pageInfo }) {
  const classes = useStyles();

  function hasTestimonialLeft() {
    return pageInfo.testimonial_left_image
      && pageInfo.testimonial_left_name
      && pageInfo.testimonial_left_description
      && pageInfo.testimonial_left_content;
  }

  function hasTestimonialRight() {
    return pageInfo.testimonial_right_image
      && pageInfo.testimonial_right_name
      && pageInfo.testimonial_right_description
      && pageInfo.testimonial_right_content;
  }

  function hasAnyTestimonials() {
    return hasTestimonialLeft() || hasTestimonialRight();
  }

  if (!hasAnyTestimonials()) return null;

  return (
    <div style={{ backgroundColor: '#7ED321'}}>
      <Container maxWidth="lg" style={{ fontFamily: 'poppins', fontWeight: 'bold', paddingBottom: '10rem' }}>
        <h5 style={{ margin: 0, paddingTop: '4rem', fontSize: '1rem' }}>Depoimentos</h5>
        <h1 style={{ margin: 0, fontSize: '3.5rem', marginBottom: '2.31rem' }}>Vejam o que estão achando</h1>
        <Grid container spacing={3}>
          {hasTestimonialLeft()
          && (
          <Grid item xs={12} sm={6}>
            <div style={{ backgroundColor: 'white', padding: 30, borderRadius: 3 }}>
              <div style={{ display: 'flex', alignItems: 'center', color: '#333333', marginBottom: '2.25rem' }}>
                <img className={classes.imgBall} src={pageInfo.testimonial_left_image} alt="depoimento-1" />
                <div>
                  <p style={{ margin: 0, fontSize: '1.5rem', fontWeight: 'bold' }}>{pageInfo.testimonial_left_name}</p>
                  <p style={{ margin: 0, fontSize: '1rem', fontWeight: '600', fontFamily: 'Source Sans Pro' }}>{pageInfo.testimonial_left_description}</p>
                </div>
              </div>
              <p style={{ fontWeight: 'normal', fontFamily: 'Source Sans Pro', color: '#333333' }}>{pageInfo.testimonial_left_content}</p>
            </div>
          </Grid>
          )}
          {hasTestimonialRight()
          && (
          <Grid item xs={12} sm={6}>
            <div style={{ backgroundColor: 'white', padding: 30, borderRadius: 3 }}>
              <div style={{ display: 'flex', alignItems: 'center', color: '#333333', marginBottom: '2.25rem' }}>
                <img className={classes.imgBall} src={pageInfo.testimonial_right_image} alt="depoimento-2" />
                <div>
                  <p style={{ margin: 0, fontSize: '1.5rem', fontWeight: 'bold' }}>{pageInfo.testimonial_right_name}</p>
                  <p style={{ margin: 0, fontSize: '1rem', fontWeight: '600', fontFamily: 'Source Sans Pro' }}>{pageInfo.testimonial_right_description}</p>
                </div>
              </div>
              <p style={{ fontWeight: 'normal', fontFamily: 'Source Sans Pro', color: '#333333' }}>{pageInfo.testimonial_right_content}</p>
            </div>
          </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

Testimonials.propTypes = {
  pageInfo: PropTypes.shape({
    page_policts: PropTypes.string.isRequired,
    page_terms: PropTypes.string.isRequired,
    testimonial_left_content: PropTypes.string.isRequired,
    testimonial_left_description: PropTypes.string.isRequired,
    testimonial_left_image: PropTypes.string.isRequired,
    testimonial_left_name: PropTypes.string.isRequired,
    testimonial_right_content: PropTypes.string.isRequired,
    testimonial_right_description: PropTypes.string.isRequired,
    testimonial_right_image: PropTypes.string.isRequired,
    testimonial_right_name: PropTypes.string.isRequired,
  }).isRequired,
};
