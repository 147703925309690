import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import Header from '../components/Header';
import About from '../components/About';
import ApplicantArea from '../components/ApplicantArea';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import RegistrationForm from '../components/RegistrationForm';
import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';
import VideoPresentation from '../components/VideoPresentation';

export default function Home({ pageInfo, apiUrl, affiliateApiToken }) {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const { search = '' } = location;
    if (search.match(/to_signup=true/)) {
      history.replace('/');
      const registrationForm = document.querySelector('.registration-form');
      animateScroll.scrollTo(registrationForm.offsetTop, {
        duration: 1500,
        delay: 100,
        smooth: 'easeInOutQuint',
      });
    }
  }, [location.search]);

  return (
    <div style={{ position: 'relative' }} id="home-container">
      <Header />
      <VideoPresentation pageInfo={pageInfo} />
      <About />
      <ApplicantArea />
      <Testimonials pageInfo={pageInfo} />
      <RegistrationForm
        apiUrl={apiUrl}
        affiliateApiToken={affiliateApiToken}
      />
      <FAQ />
      <Newsletter />
      {/* <Footer /> */}
    </div>
  );
}

Home.propTypes = {
  pageInfo: PropTypes.shape({
    page_policts: PropTypes.string,
    page_terms: PropTypes.string,
    testimonial_left_content: PropTypes.string.isRequired,
    testimonial_left_description: PropTypes.string.isRequired,
    testimonial_left_image: PropTypes.string.isRequired,
    testimonial_left_name: PropTypes.string.isRequired,
    testimonial_right_content: PropTypes.string.isRequired,
    testimonial_right_description: PropTypes.string.isRequired,
    testimonial_right_image: PropTypes.string.isRequired,
    testimonial_right_name: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    thumb_video: PropTypes.string.isRequired,
  }),
};

Home.defaultProps = {
  pageInfo: {
    page_policts: '',
    page_terms: '',
  },
};
