import React from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root:{
      margin: '15px 0px',
    },
    label: {
      fontSize: '0.75rem',
      color: '#333333 !important',
      fontWeight: 'bold',
      fontFamily: 'Source Sans Pro',
      marginBottom: '10px',
    },
    labelOption:{
      '& span': {
        fontSize: '0.75rem',
        fontFamily: 'Source Sans Pro',
      }
    },
    errorRegistration: {
      color: "crimson",
      fontFamily: "Source Sans Pro",
      marginBottom: "-10px",
    },
}));

const CustomCheckbox = withStyles({
    root: {
      color: '#B6B6B6',
      '&$checked': {
        color: '#7ED321',
      },
    },
    checked: {},
})((props) => <Checkbox {...props} />);

const CustomCheckboxGroup = ({
  label,
  options,
  checkedValues,
  onChange,
  required = false,
  onBlur,
  error,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel required={required} className={classes.label} component="legend">{label}</FormLabel>
        <FormGroup>
          { options.map((option, index) => (
            <FormControlLabel
              key={option.value}
              control={
                <CustomCheckbox
                  name={option.value}
                  checked={checkedValues[option.value]}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              }
              label={option.label}
              className={classes.labelOption}
            />
          ))}
        </FormGroup>
        {error && <span className={classes.errorRegistration}>{error}</span>}
      </FormControl>
    </div>
  );
};

export default CustomCheckboxGroup;
