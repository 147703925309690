import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';

import { GreenButton } from '../config/theme';
import ImgNotebook from 'images/notebook.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  notebookBG: {
    backgroundColor: '#00000019',
    backgroundImage: `url(${ImgNotebook})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '39vw',
    height: 768,
    backgroundPositionY: '50px',
    '@media(max-width: 768px)': {
      backgroundImage: 'unset',
      height: 768,
    },
    '@media(max-width: 499px)': {
      backgroundImage: 'unset',
      height: 500,
    },
    '@media(max-width: 360px)': {
      backgroundImage: 'unset',
      height: 400,
    },
  },
}));

export default function ApplicantArea() {
  const classes = useStyles();
  let history = useHistory();

  function login() {
    history.push('login');
  }

  return (
    <div className={classes.notebookBG} style={{ display: 'flex' }}>
      <Container maxWidth="lg" style={{ fontFamily: 'poppins', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <Grid container>
          <Grid item md={6}>
            <h5 style={{ margin: 0, paddingTop: '4rem', fontSize: '1rem' }}>ÁREA DO AFILIADO</h5>
            <h1 style={{ margin: 0, fontSize: '4rem', marginBottom: '2.31rem', lineHeight: "1" }}>Acompanhe os seus ganhos</h1>

            <p style={{ fontFamily: 'Source Sans Pro', marginTop: 0, maxWidth: 483, fontWeight: '600' }}>
              Aqui na Casa de Apostas nós prezamos muito pela transparência e credibilidade. Nós disponibilizamos para nossos 
              Afiliados uma plataforma de gerenciamento de seus resultados completa e extremamente intuitiva. Quando você ganha, nós também ganhamos.
            </p>
            <GreenButton onClick={login} >Entrar</GreenButton>
          </Grid>
          <Grid item md={6} />

        </Grid>

      </Container>
    </div>
  );
}