import React, {useState} from 'react';
import axios from 'axios';
import { Container, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { DarkButton } from '../config/theme';
import { RegistrationInputText } from './RegistrationInputs';

const useStyles = makeStyles((theme) => ({
  inputName: {
    position: 'fixed',
    zIndex: 0,
  },
}));

export default function Newsletter() {
  const [email, setEmail] = useState('');
  const [honeyName, setHoneyName] = useState(null);
  const [emailError, setEmailError] = useState();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  function registerNews(e) {
    document.activeElement.blur();
    setLoading(true);
    toast.dismiss();

    const url = 'https://177cf095.sibforms.com';
    const path = '/serve/MUIEAJfr7t5xLpyzE-HjBeA48t1BLCDWkxyUUZ865MZm_TI4UW3_NhGEJjOrtnvmHxv3tDxKJzH3QnvqpO67e3z19fFfHtBycJ0woDkhY8so5wS0sZTkFSnjX5ShF_dlzXS7XnMdY-UiY8R9D37PYvjB_sOFtG-Fsu0HEHB8QPCyysHbIt8Vxx0v7yaiFHYNFrVIKEp3nislcITl?isAjax=1'

    const bodyFormData = new FormData();
    bodyFormData.append('EMAIL', email);
    bodyFormData.append('locale', 'pt');
    bodyFormData.append('email_address_check', '');

    axios({
      method: 'post',
      url: `${url}${path}`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        toast.success('Cadastrado com sucesso');
        setEmail('');
        setEmailError('');
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setEmailError(error.response.data.email);
        }

        if (error.response.status === 400) {
          const { errors } = error.response.data;
          const message = errors.EMAIL;
          if (message) {
            toast.error(message);
          } else {
            toast.error('Erro ao processar solicitação.');
          }
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  function handleChange(e) {
    const { value } = e.target;
    setEmail(value);
  }

  function inputsKeyHandler(e) {
    if (e.key === 'Enter') registerNews();
  }

  return (
    <div style={{ backgroundColor: '#7ED321', color: '#FFFFFF', height: 460, display: 'flex', alignItems: 'center' }}>
      <Container maxWidth="lg" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <h5 style={{ margin: 0, fontSize: '1rem' }}>FIQUE POR DENTRO</h5>
            <h1 style={{ margin: 0, fontSize: '3.5rem' }}>NOSSO NEWSLETTER</h1>
            <p style={{ margin: 0, fontSize: '0.9rem', lineHeight: '1.8', maxWidth: '33rem' }}>
              Assine nosso Newsletter e receba diretamente em seu e-mail todas as principais cotações e estatísticas dos 
              principais jogos e esportes do mundo, além de poder participar das nossas imperdíveis <span style={{ fontSize: '1.1rem'}}>promoções exclusivas</span>, 
              que você só encontra aqui na Casa de Apostas!
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div style={{ marginTop: '1.4rem', position: 'relative', zIndex: 2 }}>
              <form>
                <RegistrationInputText onKeyDown={inputsKeyHandler} placeholder="Email" name="email" value={email} error={emailError} onChange={handleChange} />
                <input onKeyDown={inputsKeyHandler} name="name" className={classes.inputName} onChange={(e) => setHoneyName(e.target.value)} />
              </form>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <DarkButton disabled={loading} onClick={registerNews}>{loading ? 'Cadastrando...' : 'Cadastrar'}</DarkButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
