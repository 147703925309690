import React from 'react';
import { Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import imgPlay from 'images/play.png';
import ScrollLink from './ScrollLink';
import { DarkButton, OutlinedButton } from '../config/theme';

export default function VideoPresentation({ pageInfo }) {
  if (!pageInfo.video) return null;
  return (
    <div style={{
      backgroundColor: '#7ED321', color: '#000000', display: 'flex', alignItems: 'center',
    }}
    >
      <Container maxWidth="lg" style={{ fontFamily: 'poppins', fontWeight: 'bold', paddingBottom: '7.75rem', paddingTop: '7.75rem' }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <div style={{ height: '25rem', maxWidth: '35.87rem', backgroundColor: 'black' }}>
              <ReactPlayer
                url={pageInfo.video || ''}
                width="100%"
                height="100%"
                playing
                controls
                loop
                playIcon={
                  <img alt="video" src={imgPlay} />
                }
                light={pageInfo.thumb_video}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingLeft: '3rem' }}>
            {/*<h5 style={{ fontSize: '1rem', margin: 0, marginBottom: '2rem' }}>ASPERIORES</h5>*/}
            <h1 style={{ fontSize: '3.5rem', margin: 0 }}>Seja afiliado da Casa de Apostas</h1>
            <p style={{
              fontFamily: 'Source Sans Pro', fontSize: '1rem', lineHeight: '1.8', maxWidth: '29.37rem', fontWeight: '600', marginBottom: '2rem',
            }}
            >
            É muito fácil fazer parte do nosso programa de afiliados. Se você tem um site, uma boa rede de relacionamentos, ou um bom engajamento nas redes sociais e gosta de esportes, seu lugar é na Casa de Apostas do Brasil e do brasileiro! Assista o vídeo ao lado, tire suas dúvidas e cadastre-se agora mesmo.
            </p>
            <div>
              <ScrollLink to="registration-form">
                <DarkButton style={{ fontFamily: 'Source Sans Pro', marginRight: '3rem' }}>Cadastrar</DarkButton>
              </ScrollLink>
              <ScrollLink to="faq-form">
                <DarkButton style={{ fontFamily: 'Source Sans Pro', backgroundColor: '#7ED321', color: 'black', border: '1px solid #333333' }}>Saiba Mais</DarkButton>
              </ScrollLink>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
VideoPresentation.propTypes = {
  pageInfo: PropTypes.shape({
    page_policts: PropTypes.string,
    page_terms: PropTypes.string,
    testimonial_left_content: PropTypes.string.isRequired,
    testimonial_left_description: PropTypes.string.isRequired,
    testimonial_left_image: PropTypes.string.isRequired,
    testimonial_left_name: PropTypes.string.isRequired,
    testimonial_right_content: PropTypes.string.isRequired,
    testimonial_right_description: PropTypes.string.isRequired,
    testimonial_right_image: PropTypes.string.isRequired,
    testimonial_right_name: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    thumb_video: PropTypes.string.isRequired,
  }).isRequired,
};
