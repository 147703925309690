import React from 'react';
import RegistrationForm from '../components/RegistrationForm';


export default function PublicForm({apiUrl, affiliateApiToken}) {


  return (
    <div>
      <RegistrationForm apiUrl={apiUrl} affiliateApiToken={affiliateApiToken} />
    </div>
  );
}
