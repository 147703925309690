import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '40px',
    margin: '10px 0px'
  }
}));

export default function FAQ() {
  // const classes = useStyles();
  // const theme = useTheme();

  return (
    <div name="faq-form" className="faq-form">
      <Container maxWidth="lg" style={{ fontFamily: 'poppins', fontWeight: 'bold', paddingBottom: '10rem' }}>
        <h1 style={{ marginTop: '1.25rem', fontSize: '3.5rem', marginBottom: '2rem' }}>Perguntas Frequentes</h1>
        <p style={{ fontSize: '1.2rem', fontFamily: 'Source Sans Pro', fontWeight: '600', margin: 0, marginBottom: '2rem' }}>Tire suas dúvidas sobre o programa de afiliados agora mesmo</p>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Quais os requisitos para fazer parte do programa de Afiliados Casa de Apostas?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
                Você precisa ser maior de 18 anos e ter uma rede de contatos, site ou canal de divulgação, os quais te permitam captar clientes para fazer parte de sua afiliação.
              </p>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Quais formas tenho para divulgar meu código promocional?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
                Você pode divulgar o seu código através de um link (promocode) que será fornecido por nossa equipe ou apenas informando aos clientes o código escolhido para seu usuário. Você pode usar nossos banners e gerar conteúdo ao falar sobre o Casa de Apostas e, desta forma, conseguirá engajar sua rede de contatos a realizarem o cadastro no site e começarem a jogar.   
              </p>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Como acompanho resultados?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
                Se você for aprovado no nosso Programa de Afiliados Casa de Apostas, poderá acompanhar todos seus resultados na nossa plataforma de gerenciamento, com seu usuário e senha criados.
              </p>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Se minha afiliação tiver resultado negativo, eu tenho prejuízo?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
                De jeito nenhum! Caso sua afiliação tenha resultado negativo no mês, você apenas não receberá a comissão. O cálculo é zerado no fechamento mensal e calculado novamente no mês seguinte, sem levar em consideração esse resultado negativo.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Como funciona o pagamento da comissão?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
                O Pagamento é calculado mensalmente, sobre o percentual dos ganhos do Casa de Apostas obtidos com os jogadores que foram indicados por você.
              </p>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Qual a periodicidade do pagamento? Como é realizado?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
                O pagamento das comissões é feito mensalmente e é realizado através de transferência bancária, mediante a solicitação do usuário e o cumprimento dos requerimentos expostos nos Termos e Condições do Programa de Afiliados.
              </p>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Qual o valor mínimo parar receber comissão?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
              O valor mínimo para a retirada de comissões é de R$ 50,00. Caso este valor não seja atingido, ele será somado aos meses seguintes, até que alcance o valor mínimo para pagamento.
              </p>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <p style={{ margin: 0, fontSize: '1rem', lineHeight: '1.8' }}>Preciso manter um número mínimo de jogadores ativos na minha rede para continuar ganhando comissões?</p>
              <p style={{ margin: 0, marginTop: '0.5rem', fontFamily: 'Source Sans Pro', fontSize: '1rem', fontWeight: 'normal', opacity: '0.5' }}>
              Nós apenas solicitamos que sejam mantidos ao menos 10 (dez) jogadores ativos na plataforma, num período de três meses. Consideramos jogadores ativos todos os jogadores que realizam apostas através de seu código promocional. A cada trimestre, você deve acumular pelo menos mais cinco usuários ativos para se manter como nosso Afiliado.
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}