import React, { useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AuthorizationClient from '../single-page/api/AuthorizationClient.js';
import { toast } from 'react-toastify';
import LoadingButton from '../components/LoadingButton.js';
import { RegistrationInputText } from './components/RegistrationInputs';
import { GreenButton } from './config/theme';

const rdmcolor = '#D12E5E';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: rdmcolor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    backgroundColor: 'white',
    minWidth: 420,
    margin: '0 auto',
    '@media(max-width: 499px)': {
      minWidth: 290,
      padding: 20,
    },
    borderRadius: 3,
    boxSizing: 'border-box',
  },
  actionLink: {
    display: 'block',
    marginTop: 5,
    textDecoration: 'none',
    color: '#333333',
    fontFamily: 'Source Sans Pro',
    fontSize: '0.9rem',
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function signIn(e) {
    e.preventDefault();
    document.activeElement.blur();
    toast.dismiss();
    setLoading(true);
    AuthorizationClient.requestPasswordReset({ email: email })
      .then((res) => {
        toast.success('Olhe sua caixa de e-mail para concluir o processo');
        history.push('/login');
      })
      .catch((response) => {
        if (response.status === 422) {
          setEmailError(response.data.errors.email.join(', '));
        } else {
          setEmailError();
        }
        if (response.data.error) {
          toast.error(`Erro: ${response.data.error}`);
        } else {
          toast.error('Erro ao processar solicitação.');
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  function handleChange(e) {
    const { value } = e.target;
    setEmail(value);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Recuperar minha senha
      </Typography>
      <form className={classes.form} onSubmit={signIn} noValidate>
        <RegistrationInputText placeholder="E-mail" name="email" value={email} error={emailError} onChange={handleChange} verticalSpacing="0px" />
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <GreenButton type="submit" disabled={loading}>{loading ? 'Aguarde...' : 'Recuperar minha senha'}</GreenButton>

          <Link className={classes.actionLink} to="/login">Fazer Login</Link>
          {/* <Link className={classes.actionLink} to="/">Voltar</Link> */}
        </div>
      </form>
    </div>
  );
}
