import React from 'react';
import bgImage from 'images/header-background.png';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import iconFacebookWhite from 'images/ic-face-white.svg';
import iconTwitterWhite from 'images/ic-twitter-white.svg';
import iconInstaWhite from 'images/ic-insta-white.svg';
import socialHeader from 'images/social-header.svg';
import { Link } from 'react-router-dom';
import { GreenButton, OutlinedButton } from '../config/theme';
import NavBar from './NavBar';
import ScrollLink from './ScrollLink';
import { animateScroll } from 'react-scroll';
// import iconFacebookWhite from ''
// import iconFacebookWhite from ''

const useStyles = makeStyles((theme) => ({
  containerSocial: {
    textAlign: 'right',
    marginRight: 75,
    '@media(max-width: 499px)': {
      marginRight: 0,
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  function handleSignUpScroll() {
    console.log('scroll: ', 'foi');
    animateScroll.scrollTo('registration-form', {
      duration: 1300,
      smooth: true,
      containerId: 'home-container',
      // offset: 50, // Scrolls to element + 50 pixels down the page
    })
  }

  function redirectToLoginPage(){
    window.location.href = '/login';
  }

  function OverlapMenuContent(props) {
    const { handleMenuState } = props;
    return (
      <ul>
        <li>
          <ScrollLink to="registration-form" onClick={handleMenuState}>
            Cadastre-se
          </ScrollLink>
        </li>
        <li>
          <Link to="/login">Entrar</Link>
        </li>
        <li>
          <a href="https://casadeapostas.com/pt-br/Page/Terms" target="_blank" rel="noreferrer">Termos e Condições</a>
        </li>
        <li>
          <Link to="/politicas">Políticas de Privacidade</Link>
        </li>
        <li>
          <a href="https://casadeapostas.com/pt-br/Page/Faq" target="_blank" rel="noreferrer">FAQ</a>
        </li>
      </ul>
    );
  }

  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}
    >
      <NavBar OverlapMenuContent={OverlapMenuContent} />
      <Container maxWidth="lg" style={{ color: 'white' }}>
        <h1 style={{
          fontFamily: 'Poppins',
          fontSize: '4rem',
          maxWidth: 776,
          marginBottom: 20,
          lineHeight: 1,
        }}
        >
          Ganhe dinheiro com sua rede de contatos
        </h1>
        <p style={{ marginTop: 0, maxWidth: 483, fontSize: '1.1em', fontWeight: 'bold' }}>O Programa de Afiliados Casa de Apostas permite que você e/ou sua empresa ganhe comissões sobre as receitas de clientes que jogarem no <a href='https://www.casadeapostas.com/' style={{ textDecoration: 'none', color: 'inherit' }} target="_blank" rel="noreferrer">CasadeApostas.com</a> através do seu código promocional.</p>
        <ScrollLink to="registration-form">
          <GreenButton>Participe</GreenButton>
        </ScrollLink>
      </Container>

      <Container maxWidth="xl" style={{ color: 'white', position: 'absolute', bottom: '3rem' }}>
        <div className={classes.containerSocial}>
          {/* <img src={socialHeader} alt="banner" style={{ marginRight: 75 }} /> */}
          <a href="https://www.facebook.com/casadeapostas.bra" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', lineHeight: 0 }}>
            <img alt="Facebook" src={iconFacebookWhite} style={{ paddingRight: '0.652rem' }} />
          </a>
          <a href="https://twitter.com/casadeapostastv" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', lineHeight: 0 }}>
            <img alt="Twitter" src={iconTwitterWhite} style={{ paddingRight: '0.652rem' }} />
          </a>
          <a href="https://www.instagram.com/casadeapostas" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', lineHeight: 0 }}>
            <img alt="Instagram" src={iconInstaWhite} />
          </a>
        </div>
      </Container>
    </div>
  );
}
