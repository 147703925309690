/* eslint-disable react/jsx-fragments */
/* eslint-disable no-undef */
import React, { useState, useRef, Fragment, useEffect } from 'react';
import logo from 'images/logo.svg';
import mobileLogo from 'images/afiliados-mobile-logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
  menuSandwich: {
    cursor: 'pointer',
    display: 'none',
    // position: 'relative',
    width: 30,
    height: 30,
    zIndex: 11,
    '& span': {
      borderTop: (props) => (props.menuOpen ? '2px solid #7ED321' : '2px solid white'),
      width: (props) => (props.menuOpen ? 45 : 30),
      display: 'block',
      marginBottom: (props) => (props.menuOpen ? 0 : 8),
      marginTop: (props) => (props.menuOpen ? -1 : 0),
      position: (props) => (props.menuOpen ? 'absolute' : 'unset'),
      top: (props) => (props.menuOpen ? '50%' : 'unset'),
    },
    '& span:nth-child(1)': {
      transform: (props) => (props.menuOpen ? 'rotate(45deg)' : 'unset'),
    },
    '& span:nth-child(2)': {
      display: (props) => (props.menuOpen ? 'none' : 'block'),
    },
    '& span:nth-child(3)': {
      transform: (props) => (props.menuOpen ? 'rotate(-45deg)' : 'unset'),
    },
    '@media(max-width: 820px)': {
      display: 'block',
    },
  },
  checkMenu: {
    display: 'none',
  },
  dropDownMenu: {
    backgroundColor: '#17252C',
    position: 'absolute',
    top: '100%',
    left: -32,
    right: -32,
    overflow: 'hidden',
    zIndex: 11,
    listStyleType: 'none',
    padding: 0,
    height: (props) => (props.menuOpen ? 'unset' : 0),
    '& li': {
      padding: 10,
    },
  },
  desktopMenu: {
    display: 'none',
    flex: 1,
    '@media(min-width: 821px)': {
      display: 'block',
    },
  },
  // navBar: {
  //   position: 'absolute',
  //   top: 24,
  //   left: 32,
  //   right: 32,
  //   zIndex: 2,
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   backgroundColor: (props) => (props.menuOpen ? '#ffffffeb' : props.overrideStyles.backgroundColor),
  //   alignItems: 'center',
  // },
  navBar: (props) => {
    return {
      position: 'absolute',
      top: 24,
      left: 32,
      right: 32,
      zIndex: 11,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      ...props.overrideStyles,
      backgroundColor: props.menuOpen ? '#ffffffeb' : props.overrideStyles.backgroundColor,
      '@media(max-width: 820px)': {
        justifyContent: 'space-between',
      },
    };
  },
  overlapMenu: {
    height: '100vh',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffff',
    transition: 'all ease-in-out 0.3s',
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 11,
    '& ul': {
      listStyle: 'none',
      padding: 0,
      '& li a': {
        fontFamily: 'Source Sans Pro',
        textDecoration: 'none',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        color: '#333333',
      },
    },
  },
  mobileBalance: {
    margin: 0,
    color: 'white',
    position: 'absolute',
    width: 100,
    fontWeight: 'bold',
    textAlign: 'right',
    bottom: 29,
    right: 65,
    fontSize: '1.2rem',
    lineHeight: 1,
  },
}));

export default function MobileFirstMenu({ OverlapMenuContent, children, overrideStyles = { backgroundColor: 'transparent' }, clientBalance = 0.0 }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles({ menuOpen, overrideStyles });
  const overlapMenuRef = useRef(null);

  const isMobile = useMediaQuery('(max-width:820px)');

  function handleMenuState() {
    setMenuOpen(!menuOpen);
  }

  function setOverlapMenuDisplay(display) {
    if (display === 'flex') {
      overlapMenuRef.current.style.display = display;
      setTimeout(() => {
        overlapMenuRef.current.style.opacity = 1;
      }, 10);
    } else {
      overlapMenuRef.current.style.opacity = 0;
      setTimeout(() => {
        overlapMenuRef.current.style.display = display;
      }, 301);
    }
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if (menuOpen) {
      window.scrollTo(0, 0);
      body.style.overflow = 'hidden';
      setOverlapMenuDisplay('flex');
    } else {
      body.style.overflow = 'auto';
      setOverlapMenuDisplay('none');
    }
  }, [menuOpen]);

  return (
    <Fragment>
      <div className={classes.overlapMenu} ref={overlapMenuRef}>
        {OverlapMenuContent && <OverlapMenuContent handleMenuState={handleMenuState} />}
      </div>
      <div className={classes.navBar}>
        {!menuOpen ? <img src={isMobile ? mobileLogo : logo} alt="logo" /> : <div style={{ width: 202, height: 38.63 }} />}
        <div className={classes.menuSandwich} onClick={handleMenuState} onKeyDown={handleMenuState}>
          <p className={classes.mobileBalance}><small style={{ fontWeight: 'normal', display: 'block' }}>Comissão</small><small style={{ color: '#5BB349' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(clientBalance)}</small></p>
          <div>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={classes.desktopMenu}>
          {children}
        </div>
      </div>
    </Fragment>
  );
}
